import React from 'react'
import PropTypes from 'prop-types'

const Card = (props) => {
  const { footer, header, children, title } = props
  return (
    <div className='md:mt-0 md:col-span-3 border-t-2 shadow border-cccorange dark:border-cccpurple-alt bg-cccblue bg-opacity-5 dark:bg-gray-800'>
      <div className='flex justify-between items-start'>
        { title && <div className='font-medium leading-6 text-cccorange dark:text-cccpurple px-2 sm:px-3 pt-1'> {title} </div> }
        { header && <div className='px-2 pt-2 text-right sm:pl-3 sm:pt-3'> {header} </div> }
      </div>
      <div className='p-2 space-y-2 sm:p-3'>
        { React.Children.map(children, (child) => {
          return (child && <> { child } </>)
        })}
      </div>
      { footer && <div className='px-2 py-1 text-right sm:px-6'> {footer} </div> }
    </div>
  )
}

export default Card

Card.propTypes = {
  footer: PropTypes.object,
  header: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  light: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.array,
    PropTypes.string,
    PropTypes.object
  ])
}
