import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from "framer-motion";

import { FiAlertCircle, FiImage } from "react-icons/fi";
import { FaImage, FaVideo, FaFileAudio, FaXmark } from "react-icons/fa6";
import { Dialog, DialogPanel } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Icon = ({ icon }) => {
  if (!icon) { return <FiAlertCircle className="text-slate-900/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" /> }
  if (icon === 'image') { return <FaImage className="text-slate-900/10 rotate-12 text-[250px] absolute z-0 -top-16 -left-16" /> }
  if (icon === 'video') { return <FaVideo className="text-slate-900/10 rotate-12 text-[250px] absolute z-0 -top-16 -left-16" /> }
  if (icon === 'audio') { return <FaFileAudio className="text-slate-900/10 rotate-12 text-[250px] absolute z-0 -top-16 -left-16" /> }
}

const Modal = (props) => {
  const { isOpen, closeModal, full, children, icon, maxWidth, title } = props
  const maxW = maxWidth || full ? "max-w-7xl" : 'max-w-xl'

  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={closeModal}
          as="div"
          className='bg-slate-900/20 backdrop-blur p-2 fixed inset-0 z-50 overflow-y-scroll'
        >
          <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
            <motion.div
              initial={{ scale: 0, rotate: "12.5deg" }}
              animate={{ scale: 1, rotate: "0deg" }}
              exit={{ opacity: 0 }}
              className="flex min-h-full items-center justify-center p-4"
            >
              <div className={`bg-gradient-to-br from-cccbeige to-cccbeige-alt text-slate-900 p-6 rounded-lg w-full shadow-xl cursor-default relative overflow-hidden ${maxW}`} >
                <Icon icon={icon} />
                <FaXmark onClick={closeModal} className="cursor-pointer hover:text-slate-700 text-slate-900 w-6 h-6 absolute z-10 top-3 right-3" />

                {title && <div className="sm:text-center">
                  <h2 className="text-3xl font-extrabold text-slate-900 tracking-tight sm:text-4xl">{title}</h2>
                </div> }
                <DialogPanel className="relative">
                  { React.Children.map(children, (child) => {
                    return (child && <> { child } </>)
                  })}
                </DialogPanel>
              </div>
            </motion.div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}

export default Modal;

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  full: PropTypes.bool
}
