import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useGlobalState } from '@/state'

import SVGIcon from '@/components/icon'

const NewBadge = () => <span className="flex-shrink-0 inline-block px-2 ml-1 mb-1 py-0.5 text-white bg-red-500 text-xs font-medium rounded-full flex cursor-pointer">New</span>

const CountBadge = ({ count }) => {
  if (count < 1) { return null }

  return <span className="px-2 py-1 text-white bg-red-500 text-xs rounded-xl cursor-pointer">{count}</span>
}

const newStyles = {
  orange: {
    selectedAnchor: 'bg-cccorange-alt bg-opacity-10 border-cccorange text-cccorange hover:text-cccorange',
    unselectedAnchor: 'border-transparent text-cccorange hover:text-cccorange-alt dark:text-cccorange hover:bg-gray-200',
    defaultAnchor: 'group flex items-center space-x-2 border-l-4 px-3 py-2 text-sm font-medium rounded-r-md',
    selectedIcon: 'text-cccorange',
    unselectedIcon: 'text-cccorange hover:text-cccorange-alt dark:text-cccorange',
    defaultIcon: 'mr-3 flex-shrink-0 h-6 w-6'
  },
  purple: {
    selectedAnchor: 'bg-cccpurple-alt bg-opacity-10 border-cccpurple text-cccpurple hover:text-cccpurple',
    unselectedAnchor: 'text-cccpurple border-transparent hover:text-cccpurple-alt dark:text-cccpurple hover:bg-gray-200',
    defaultAnchor: 'group flex items-center border-l-4 px-3 py-2 text-sm font-medium rounded-r-md',
    selectedIcon: 'text-cccpurple hover:text-cccpurple',
    unselectedIcon: 'text-cccpurple hover:text-cccpurple-alt dark:text-cccpurple',
    defaultIcon: 'mr-3 flex-shrink-0 h-6 w-6'
  },
  blue: {
    selectedAnchor: 'bg-cccblue-alt bg-opacity-10 border-cccblue text-cccblue hover:text-cccblue',
    unselectedAnchor: 'text-cccblue border-transparent hover:text-cccblue-alt dark:text-cccblue hover:bg-gray-200',
    defaultAnchor: 'group flex items-center border-l-4 px-3 py-2 text-sm font-medium rounded-r-md',
    selectedIcon: 'text-cccblue hover:text-cccblue',
    unselectedIcon: 'text-cccblue hover:text-cccblue-alt dark:text-cccblue',
    defaultIcon: 'mr-3 flex-shrink-0 h-6 w-6'
  }
}

const isActivePath = (name) => {
  const location = useLocation()
  const activePath = location.pathname
  if (name === 'Dashboard') { return activePath.includes('/dashboard') }
  if (name === 'Talent Search') { return activePath.includes('/talent') }
  if (name === 'Production Credits') { return activePath.includes('/credits') }
  if (name === 'Premium Perks') { return activePath.includes('/perks') }
  if (name === 'My Profile') { return activePath.includes('/profile') }
  if (name === 'My Roles') { return activePath.includes('/myroles') }
  if (name === 'Messages') { return activePath.includes('/private_messages') }
  if (name === 'My Submissions') { return ['/auditions', '/submissions'].includes(activePath) }
  if (name === 'Saved Searches') { return activePath.includes('/saved_searches') }
  if (name === 'Favorites') { return activePath.includes('/favorites') }
  if (name === 'Manage Projects') { return activePath.includes('/manage/projects') }
  if (name === 'Recommendations') { return activePath.includes('/recommendations') }
  if (name === 'Events') { return activePath.includes('/events') }
  return false
}

const MainNavSidebarItem = ({ url, name, color, callback, customIcon }) => {
  const history = useHistory()
  const active = isActivePath(name)
  const [notificationCounts] = useGlobalState('notificationCounts')
  const [useReactRouter] = useGlobalState('useReactRouter')

  const goToLink = () => {
    if (useReactRouter) {
      history.push(url)
    } else {
      window.location.href = url
    }
    if (typeof callback === 'function') {
      callback()
    }
  }

  const theStyle = active ? newStyles[color].selectedAnchor : newStyles[color].unselectedAnchor
  const defaultStyle = 'group flex w-full justify-start items-center space-x-2 border-l-4 px-3 py-2 text-sm font-medium rounded-r-md'

  if ([
    'Production Credits', 'My Profile', 'My Submissions', 'Premium Perks', 'Dashboard',
    'Favorites', 'cccCoins', 'My Roles', 'Events',
    'Messages', 'Talent Search', 'Manage Projects', 'Saved Searches'
  ].includes(name)) {
    return (
      <button onClick={goToLink} className={`${defaultStyle} ${theStyle}`} aria-current={active ? 'page' : undefined} aria-label={name} >
        <SVGIcon name={customIcon} className='h-6 w-6' />
        <span>{name}</span>
        { /*name === 'Saved Searches' && <NewBadge />*/ }
        { name === 'Messages' && <CountBadge count={notificationCounts.messages} /> }
        { name === 'Events' && <CountBadge count={notificationCounts.eventsCount} /> }
        { name === 'My Roles' && <CountBadge count={notificationCounts.incompleteRoles} /> }
      </button>
    )
  }
  return (
    <a href={url} className={`${defaultStyle} ${theStyle}`} aria-current={active ? 'page' : undefined} aria-label={name} >
      <SVGIcon name={customIcon} className='h-6 w-6' />
      <span>{name}</span>
    </a>
  )
}
export default MainNavSidebarItem

MainNavSidebarItem.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  customIcon: PropTypes.string.isRequired,
  callback: PropTypes.func
}

CountBadge.propTypes = {
  count: PropTypes.number.isRequired
}
