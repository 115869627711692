import React, { useEffect, useState } from "react";
import { FiCheckSquare, FiX } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { createConsumer } from "@rails/actioncable"

const NOTIFICATION_TTL = 30000;

const PopupAlerts = () => {
  const [toasts, setToasts] = useState([]);
  const removeToast = (id) => {
    setToasts((pv) => pv.filter((n) => n.id !== id));
  };

  const addToast = (data) => {
    // add toast only if it doesn't already exist
    if (toasts.find((n) => n.id === data.id)) return;

    //console.log('adding toast', data.id, toasts)

    setToasts((pv) => [...pv, {...data}]);
    const timeoutRef = setTimeout(() => {
      removeToast(data.id);
    }, NOTIFICATION_TTL);
  };

  useEffect(() => {
    const consumer = createConsumer()
    consumer.subscriptions.create("NotificationsChannel", {
      connected() {
        console.log('1connected to notifications channel')
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        console.log('1disconnected from notifications channel')
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        //console.log(data);

        if (data.data?.id) { addToast(data.data) }
      }
    });
  }, []);

  return <div className="flex flex-col gap-1 w-72 fixed bottom-5 left-3 z-50 pointer-events-none">
    <AnimatePresence>
      { toasts.map((n) => (
        <Popup removeNotif={removeToast} {...n} key={n.id} />
      ))}
    </AnimatePresence>
  </div>
}

const Popup = ({ message, type, title, image_url, href, id, removeNotif }) => {
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      removeNotif(id);
    }, NOTIFICATION_TTL);

    return () => clearTimeout(timeoutRef);
  }, []);

  return (
    <motion.div
      layout
      initial={{ y: -15, scale: 0.95 }}
      animate={{ y: 0, scale: 1 }}
      exit={{ x: "100%", opacity: 0 }}
      transition={{ duration: 0.35, ease: "easeOut" }}
      className={`p-2 flex items-start rounded font-medium shadow-lg text-white bg-cccblue pointer-events-auto border-2 border-black`}
    >
      { type === 'profile_view' && <a href={href} target="_blank" className="w-full flex">
        <div className="overflow-hidden rounded-sm w-12 h-12">
          <img src={image_url} alt="" />
        </div>
        <div className="pl-3 flex flex-col justify-between">
          <h6 className="font-semibold text-white">{title}</h6>
          <span className='text-white text-sm'>{message}</span>
        </div>
      </a>
      }
      { type === 'alert' && <a href={href} target="_blank" className="w-full flex">
        <div className="pl-3 flex flex-col justify-between">
          <h6 className="font-semibold text-white">{title}</h6>
          <span className='text-white text-sm capitalize'>{message}</span>
        </div>
      </a>
      }
      <button onClick={() => removeNotif(id)} className="ml-auto mt-0.5">
        <FiX />
      </button>
    </motion.div>
  );
};

export default PopupAlerts;
