import React, { Fragment, useState, useRef } from 'react'
import { Transition, Menu } from '@headlessui/react'
import { useGlobalState } from '@/state'
import SiteNotification from '@/shared/SiteNotification'
import Tippy, { useSingleton } from '@tippyjs/react'
import PropTypes from 'prop-types'
import FooterPlayer from '@/Layout/FooterPlayer'
import PopupAlert from '@/shared/PopupAlert'

import EducationCard from '@/shared/EducationCard'
import Search from '@/Layout/Search'
import classNames from '@/utils/classNamesLocal'
import { ThemeToggle } from '@/shared/ThemeToggle'
import MainNavSidebarItem from '@/Layout/MainNavSidebarItem'

import {
  BellIcon,
  XIcon,
  MenuIcon,
  MailIcon,
  SelectorIcon
} from '@heroicons/react/solid'

const MainNavMenuItem = (props) => {
  const { selected, url, name } = props
  return (
    <Menu.Item>
      <a href={url} className={classNames(
        selected ? 'bg-gray-100 text-gray-900 dark:bg-gray-800' : 'text-gray-700 dark:bg-gray-700',
        'block px-4 py-2 text-sm dark:text-white '
      )}
      > {name} <span className="sr-only">Open {name}</span> </a>
    </Menu.Item>
  )
}

export default function Header (props) {
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [, setReleaseInfo] = useGlobalState('releaseInfo')
  const [hideLogo, setHideLogo] = useState(true)
  const isComponentMounted = useRef(false)
  const [, setTippyTarget] = useGlobalState('tippyTarget')
  const [source, target] = useSingleton()
  const [notificationCounts] = useGlobalState('notificationCounts')
  const [, setUseReactRouter] = useGlobalState('useReactRouter')
  const [open, setOpen] = useState(false)

  if (!isComponentMounted.current) {
    if (!currentUser?.id) {
      setCurrentUser(props.currentUser)
      setReleaseInfo(props.releaseInfo)
      setHideLogo(false)
      setUseReactRouter(false)
    }
    setTippyTarget(target)
    isComponentMounted.current = true
  }

  const close = () => { setOpen(false) }

  return (
    <nav>
      <div className="relative z-10 flex-shrink-0 flex h-16 bg-white">
        <div className="flex items-center sm:hidden bg-white dark:bg-gray-800">
          <button onClick={() => setOpen(!open)} className="bg-white dark:bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400">
            <span className="sr-only">Open main menu</span>
            {open ? (
              <XIcon className="block h-6 w-6" aria-label="Close Menu" />
            ) : (
              <MenuIcon className="block h-6 w-6" aria-label="Open Menu" />
            )}
          </button>
        </div>

        <div className="flex-1 items-center flex justify-between dark:text-white dark:bg-gray-800">
          { !hideLogo && <div className="hidden sm:flex items-center h-16 flex-shrink-0 px-4 dark:bg-gray-800 bg-white">
            <a href='/homepage'>
              <img className="h-8 w-48" src="https://ddppjbdexhxzj.cloudfront.net/logos/casting_call_club_logo.svg" alt="Casting Call Club" />
            </a>
          </div> }
          <Search />
          <div className="w-auto flex items-center  space-x-2 sm:space-x-3 dark:bg-gray-800">
            { !currentUser?.shouldHideUpgradeButton && <a href='/pricing'
              className="hidden md:block inline-flex justify-center px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md bg-gradient-to-r from-cccpurple via-cccblue to-cccorange hover:from-cccpurple hover:to-cccorange hover:text-white">
              Upgrade
            </a> }
            <a
              href="/projects/new"
              className="hidden md:block inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-200 dark:hover:bg-gray-500 bg-cccblue hover:bg-cccblue-alt hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              <span>Create Project</span>
            </a>
            <a href='/private_messages' className="relative align-middle rounded-md focus:outline-none focus:shadow-outline-cccpurple" aria-label="Messages" aria-haspopup="true">
              <MailIcon className="h-5 w-5 dark:text-white dark:hover:text-gray-100 text-gray-400 hover:text-gray-500" aria-hidden="true" />
              { notificationCounts?.messages > 0 && (
                <span aria-hidden="true" className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-sm dark:border-gray-800 rounded-full"></span>
              )}
            </a>

            <a href='/notifications' className="relative align-middle rounded-md focus:outline-none focus:shadow-outline-purple" aria-label="Notifications" aria-haspopup="true">
              <BellIcon className="h-5 w-5 dark:text-white dark:hover:text-gray-100  text-gray-400" aria-hidden="true" />
              { currentUser?.notificationsCount > 0 && (
                <span aria-hidden="true" className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-sm dark:border-gray-800 rounded-full"></span>
              )}
            </a>

            {/* Profile dropdown */}
            <Menu as="div" className="ml-1 sm:ml-3 relative sm:hidden">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-sm"
                        src={currentUser?.avatarUrl}
                        alt={currentUser?.username}
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-white ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                    >
                      <div>
                        <Menu.Item>
                          <ThemeToggle />
                        </Menu.Item>
                        <MainNavMenuItem selected={false} name={'Dashboard'} url='/dashboard' />
                        <MainNavMenuItem selected={false} name={'Account Settings'} url='/settings' />
                        <MainNavMenuItem selected={false} name={'Subscription'} url='/subscriptions' />
                        <MainNavMenuItem selected={false} name={'cccCoins'} url='/coins' />
                        <MainNavMenuItem selected={false} name={'Achievements'} url='/achievements' />
                      </div>
                      <hr />
                      <div>
                        <MainNavMenuItem selected={false} name={'Support'} url='/faq' />
                        <MainNavMenuItem selected={false} name={'Logout'} url='/logout' />
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>

            {/* User account dropdown */}
            <Menu as="div" className="relative inline-block text-left hidden md:block mt-6 md:mt-0">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="group w-full bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 rounded-md px-2 md:px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cccpurple">
                      <span className="flex w-full justify-between items-center">
                        <span className="flex min-w-0 items-center justify-between space-x-3">
                          <img
                            className="w-10 h-10 bg-gray-300 rounded-sm flex-shrink-0"
                            src={currentUser?.avatarUrl}
                            alt={currentUser?.username}
                          />
                          <span className="flex-1 flex flex-col min-w-0 w-20 md:w-32 overflow-hidden">
                            <span className="text-gray-900 dark:text-white text-sm font-medium truncate">{currentUser?.displayName}</span>
                            <span className="text-gray-500 dark:text-gray-100 text-sm truncate">@{currentUser?.username}</span>
                          </span>
                        </span>
                        <SelectorIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="z-10 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-opacity-5 focus:outline-none"
                    >
                      <div>
                        <Menu.Item>
                          <ThemeToggle />
                        </Menu.Item>
                        <MainNavMenuItem selected={false} name={'Dashboard'} url='/dashboard' />
                        <MainNavMenuItem selected={false} name={'Account Settings'} url='/settings' />
                        <MainNavMenuItem selected={false} name={'Subscription'} url='/subscriptions' />
                      </div>
                      <hr />
                      <div>
                        <MainNavMenuItem selected={false} name={'cccCoins'} url='/coins' />
                        <MainNavMenuItem selected={false} name={'Achievements'} url='/achievements' />
                      </div>
                      <hr />
                      <div>
                        <MainNavMenuItem selected={false} name={'Support'} url='/faq' />
                        <MainNavMenuItem selected={false} name={'Logout'} url='/logout' />
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      { open && <div className="sm:hidden">
        <div className="flex-1 relative overflow-y-auto h-screen focus:outline-none dark:bg-gray-700 bg-gray-100">
          <div className="pt-2 pb-3 space-y-1">
            {/* can dupe all these links mobile/desktop */}

            <div className='flex justify-center mb-2 mt-auto'>
              { !currentUser?.shouldHideUpgradeButton && <a href='/pricing'
                className="inline-flex justify-center px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md bg-gradient-to-r from-cccpurple via-cccblue to-cccorange hover:from-cccpurple hover:to-cccorange">
                Upgrade
              </a> }
              <a href='/projects/new'
                className="inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-200 dark:hover:bg-gray-500 bg-cccblue hover:bg-cccblue-alt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              >
                <span>Create Project</span>
              </a>
            </div>
            <MainNavSidebarItem callback={close} color='orange' name='Open Projects' url='/' customIcon='icon-open-projects' />
            <MainNavSidebarItem callback={close} color='orange' name='Talent Search' url='/talent' customIcon='icon-talent-search' />
            <MainNavSidebarItem callback={close} color='orange' name='Dashboard' url='/dashboard' customIcon='icon-home' />
            <MainNavSidebarItem callback={close} color='orange' name='My Profile' url={`/${currentUser.username}`} customIcon='icon-profile' />
            <MainNavSidebarItem callback={close} color='orange' name='Production Credits' url='/credits' customIcon='icon-credits' />
            <MainNavSidebarItem callback={close} color='orange' name='Premium Perks' url='/perks' customIcon='icon-perks' />
            <MainNavSidebarItem callback={close} color='orange' name='Recommendations' url='/recommendations' customIcon='icon-recommendation' />
            <MainNavSidebarItem callback={close} color='orange' name='Events' url='/events' customIcon='icon-calendar' />
            <p className="px-3 mb-0 text-xs font-semibold text-gray-400 uppercase tracking-wider">For Talent</p>
            <MainNavSidebarItem callback={close} color='purple' name='My Submissions' url='/submissions' customIcon='icon-submissions' />
            <MainNavSidebarItem callback={close} color='purple' name='My Roles' url='/myroles' customIcon='icon-my-roles' />
            <MainNavSidebarItem callback={close} color='purple' name='Saved Searches' url='/saved_searches' customIcon='icon-saved-searches' />
            <MainNavSidebarItem callback={close} color='purple' name='Favorites' url='/favorites' customIcon='icon-favorites' />
            <p className="px-3 mb-0 text-xs font-semibold text-gray-400 uppercase tracking-wider">For Talent Seekers</p>
            <MainNavSidebarItem callback={close} color='blue' name='Manage Projects' url='/manage/projects' customIcon='icon-manage-projects' />
          </div>
          <EducationCard />
        </div>
      </div> }
      <Tippy singleton={source} />
      <SiteNotification />
      <FooterPlayer />
      <PopupAlert />
    </nav>
  )
}

Header.propTypes = {
  releaseInfo: PropTypes.string,
  currentUser: PropTypes.object
}

MainNavMenuItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}
